// src/composables/useRouteStore.js
import { ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
const routeHistory = ref([]);

export function useRouteStore() {
  const router = useRouter();
  const route = useRoute();

  // Store the current route
  function storeCurrentRoute() {
    // Store the current route full path
    if (routeHistory.value.length === 0 || routeHistory.value[routeHistory.value.length - 1] !== route.fullPath) {
      routeHistory.value.push(route.fullPath);
    }
  }

  // Go back to the previous route
  function goTo(previousRoute) {
    if (previousRoute == '/en' || previousRoute == '/fi') {
      // If no previous route, go to home
      router.push({name: 'Home'});
    } else {
      const { href } = router.resolve({ path: previousRoute });
      router.push(href);
    }
  }

  // Go to the parent route
  function goToParent() {
    const parentPath = route.path?.split('/').slice(0, -1).join('/');
    if (parentPath) {
      const { href } = router.resolve({ path: parentPath });
      router.push(href);
    } else {
      router.push('/'); // If no parent, go to home
    }
  }

  return {
    storeCurrentRoute,
    goTo,
    goToParent,
    routeHistory
  };
}
